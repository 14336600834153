// 年费
<template>
  <div style="min-width: 1381px">
    <el-row :gutter="15" style="min-height:calc(100vh - 130px)" class="flex">
      <el-col :span="18">
        <div style="height:100%">
          <el-card style="height:100%">
            <div class="top">
              <span class="mr-20 bg-b">{{ service_name }}</span>
              <span class="mr-10 color-a f14">{{ patent_code }}</span>
              <span>{{ name }}</span>
            </div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              border
              :header-cell-style="{
                color: '#222530',
                fontSize: '14px',
                backgroundColor: '#eaf1ff',
                borderColor: '#ccc',
              }"
              style="font-size: 10px; margin-top: 30px"
            >
              <el-table-column class="pr" width="40">
                <template slot-scope="scope">
                  <div
                    class="pa flex-jc-alc"
                    :class="scope.row.is_pay==0?'cur':''"
                    style="width: 100%; height: 100%; top: 0; left: 0"
                    @click="selectchange(scope.row)"
                  >
                    <i v-if="scope.row.active" class="el-icon-circle-check f18 blue"></i>
                    <div
                      :class="scope.row.is_pay==1?'is_pay':''"
                      v-else
                      style="
                        width: 16px;
                        height: 16px;
                        border: 1px solid #bbb;
                        border-radius: 50%;
                      "
                    ></div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop label="黄票编号" align="center"></el-table-column> -->
              <el-table-column label="缴费状态" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.is_overtime">已超时</div>
                  <div v-else>{{ scope.row.is_pay == 1 ? "已缴费" : "未缴费" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="费用种类" align="center">
                <template slot-scope="scope">第{{ scope.row.year }}年年费</template>
              </el-table-column>
              <el-table-column prop="price" label="应缴金额(元)" align="center"></el-table-column>
              <el-table-column prop="years_date" label="缴费截止日期" align="center"></el-table-column>
              <el-table-column  prop="printing_price" label="印花税" align="center"></el-table-column>
              <!-- <el-table-column prop="kdate" label="是否开具黄票" align="center"></el-table-column>
              <el-table-column prop="kdate" label="是否开具发票" align="center"></el-table-column> -->
              <el-table-column prop="percents" label="费减比例" align="center"></el-table-column>
              <!-- <el-table-column label="操作" width="263" align="center">
                <el-tag effect="plain" class="mr-10">预览</el-tag>
                <el-tag effect="plain" class="mr-10">下载</el-tag>
              </el-table-column>-->
            </el-table>
            <div class="flex-j-c">
              <div class="pay f16" @click="annualPay()">去缴费</div>
            <div class="pay f16" @click="$router.go(-1)">返回</div>
            </div>
          </el-card>
        </div>
        <!-- 费减 -->
        <el-dialog title="费减比例" :visible.sync="dialogVisible" width="30%">
          <el-input style="width: 100%"></el-input>
          <div class="flex-j-b f12 mt-20">
            <span>请填入真实的费减比例，如对比例不清晰请联系商务填写</span>
            <span class="red">联系商务></span>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="annualPay()">确 定</el-button>
          </span>
        </el-dialog>
      </el-col>
      <el-col :span="6">
        <div style="height: 100%;" class="gd">
          <monitor></monitor>
          <infor style="flex:1"></infor>
        </div>
      </el-col>
    </el-row>
    <!-- 监控费 -->
    <div v-if="montfee" class="mont-fee">
      <div class="mont-fee-cont">
        <div class="f18 bd color-0 t-c">专利监控服务</div>
        <div class="f12" style="margin-top: 37px">您未开通监控服务（开通后您将同时获得专利【年费监控】【状态监控】功能使用权限）</div>
        <div class="flex mt-30">
          <div class="mr-30 f14 bd">监控时间</div>
          <div>
            <el-radio v-model="radio" label="1">1年</el-radio>
            <el-radio v-model="radio" label="2">2年</el-radio>
            <el-radio v-model="radio" label="3">3年</el-radio>
            <el-radio v-model="radio" label="4">4年</el-radio>
            <el-radio v-model="radio" label="5">5年</el-radio>
            <el-radio v-model="radio" label="6">6年</el-radio>
            <el-radio v-model="radio" label="7">7年</el-radio>
            <el-radio v-model="radio" label="8">8年</el-radio>
            <el-radio v-model="radio" label="9">9年</el-radio>
            <el-radio v-model="radio" label="10">10年</el-radio>
          </div>
        </div>
        <div class="flex mt-30">
          <div class="mr-30 f14 bd">监控条数</div>
          <div>
            <el-radio v-model="radio1" label="1">100条</el-radio>
            <el-radio v-model="radio1" label="2">200条</el-radio>
            <el-radio v-model="radio1" label="3">300条</el-radio>
            <el-radio v-model="radio1" label="4">400条</el-radio>
            <el-radio v-model="radio1" label="5">500条</el-radio>
            <el-radio v-model="radio1" label="6">600条</el-radio>
            <el-radio v-model="radio1" label="7">700条</el-radio>
            <el-radio v-model="radio1" label="8">800条</el-radio>
            <el-radio v-model="radio1" label="9">900条</el-radio>
            <el-radio v-model="radio1" label="10">1000条</el-radio>
          </div>
        </div>
        <div class="flex-al mt-30">
          <span class="f14 bd color-0" style="margin-right: 15px">应付金额：</span>
          <span class="f24 bd red mr-30">1000元</span>
          <span class="f12">开通后有效时间：2021-05-17至2022-05-17</span>
        </div>
        <div class="m-0 mont-fee-btn" @click="$router.push('/confirm')">确认提交</div>
        <i class="el-icon-close cancle" @click="montfee = false"></i>
      </div>
    </div>
    <!-- 请完善案件必要信息 -->
    <el-dialog top="30vh" :title="pauId" :visible.sync="dialogVisible2" width="400px">
      <div>
        <div class="f12 t-c" style="color:red">请完善案件必要信息</div>
        <div v-if="ann1" class="t-c mt-30">
          <span class="mr-10">年费已缴至</span>
          <el-select v-model="annual_fee_by_time_num" placeholder="请选择">
            <el-option v-for="item in sum" :key="item" :label="item+'年'" :value="item"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span style="width: 70px;display: inline-block;" class="mr-10">费减比例</span>
          <el-select v-model="percents" placeholder="请选择">
            <el-option label="无费减" value="无费减"></el-option>
            <el-option label="70%" value="70%"></el-option>
            <el-option label="85%" value="85%"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span style="width: 70px;display: inline-block;" class="mr-10">费减至</span>
          <el-select v-model="years" placeholder="请选择">
            <el-option v-for="item in sum" :key="item" :label="item+'年'" :value="item"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span class="mr-10">费减受理日</span>
          <el-date-picker type="date" v-model="cost_deduction_time" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
         <div v-if="ann3" class="t-c mt-30">
          <span class="mr-10">办理登记日</span>
          <el-date-picker placeholder="办理登记通知书发文日期" type="date" v-model="check_in_time" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div class="t-c" style="margin-top:40px">
          <span
            class="bg-b cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
            @click="dialogVisible2=false"
          >取消</span>
          <span
            @click="onSubmit"
            class="bg-b ml-20 cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
          >提交</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import monitor from "./common/monitor";
import infor from "./common/Infor";
export default {
  components: {
    monitor,
    infor
  },
  created() {
    let params = this.$route.query;
    this.service_name = params.service_name;
    this.patent_code = params.patent_code;
    this.patent_id = params.id;
    this.is_trusteeship = params.is_trusteeship;
    this.name = params.name;
    this.s_id = params.service_id;
    this.pauId = "专利号：" + this.patent_code;
    this.pauIds =this.patent_id;
    this.getList(this.patent_id);
  },
  data() {
    return {
      ann1: false,
      ann2: false,
      ann3:false,
      price_not: "",
      percents: "",
      years: "",
      patent_id: "",
      is_trusteeship:'',
      annual_fee_by_time_num: "",
      cost_deduction_time: "",
      check_in_time:"",
      pauId: "",
      pauIds: "",
      s_id: '',
      dialogVisible2: false, //年费已缴至
      years1: "",
      name: "",
      service_name: "",
      patent_code: "", //专利号
      patent_id: "", //案件id
      montfee: false,
      radio1: "1",
      radio: "1",
      pagesize: 2,
      page: 1,
      total: 0,
      tableData: [],
      dialogVisible: false
    };
  },
  methods: {
    getList() {
      this.$request
        .get(`/client/annual_fee/annualFeeInfo?patent_id=${this.patent_id}&is_trusteeship=${this.is_trusteeship}`)
        .then(res => {
          if(res.data.code==200){
             this.tableData = res.data.data;
          this.tableData.forEach(item => {
            item.active = false;
          });
          }
         if (res.data.code == 1101) {
            if(res.data.data.percents==0||res.data.data.percents=='无费减'||res.data.data.percents=='无费减'){
                  res.data.data.percents=''
              }
            if(res.data.data.percents.indexOf('%')==-1 && res.data.data.percents.indexOf("％") == -1&&res.data.data.percents!=''){
                res.data.data.percents=res.data.data.percents+'%'
              }
            this.price_not = res.data.data.price_not;
            this.annual_fee_by_time_num = res.data.data.annual_fee_by_time;
            this.percents = res.data.data.percents;
            this.years = res.data.data.years == 0 ? "" : res.data.data.years;
            this.cost_deduction_time = res.data.data.cost_deduction_time;
            this.check_in_time = res.data.data.check_in_time;
            if (this.annual_fee_by_time_num) {
              this.ann1 = false;
            } else {
              this.ann1 = true;
            }
            if (this.price_not == 2||this.price_not == 3) {
              if (this.percents && this.years && this.cost_deduction_time) {
                this.ann2 = false;
              } else {
                this.ann2 = true;
              }
            }
            if (this.price_not == 1||this.price_not == 0) {
              this.ann2 = false;
            }
            if (!this.check_in_time) {
              this.ann3 = true;
            }else{
              this.ann3 = false
            }
            this.dialogVisible2 = true;
          }
        });
    },
    changePage(index) {
      this.page = index;
      this.getList();
    },
    change($event, id) {
      let status = $event ? 1 : 0;
      this.$request
        .post("/client/annual_fee/setControlStatus", {
          patent_id: id,
          status
        })
        .then(res => {
          if (res.data.code == 400) {
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = !status;
              }
            });
            this.montfee = true;
          }
        });
    },
    annualPay() {
      // this.dialogVisible = false
       let status= this.tableData.every(item=>{
              return item.is_overtime==false
         })
    if(!status){
      this.$message("已超时无法继续缴费");
      return
    }
      if (this.years1.length == 0) {
        return this.$message("请选择要缴年费选项");
      }
      this.$router.push({
        path: "/confirm",
        query: {
          type: 1,
          patent_id: this.patent_id,
          years: this.years1,
          is_trusteeship:this.is_trusteeship
        }
      });
    },
    selectchange(row) {
      let years1 = [];
      let tableData = [...this.tableData];
      if (row.is_pay == 1) {
        return;
      }
      if (row.active == false) {
        tableData.forEach(item => {
          if (item.is_pay == 0 && item.year <= row.year) {
            item.active = true;
          }
        });
        this.tableData = tableData;
      } else {
        tableData.forEach(item => {
          if (item.is_pay == 0 && item.year >= row.year) {
            item.active = false;
          }
        });
        this.tableData = tableData;
      }
      this.tableData.forEach(item => {
        if (item.active) {
          years1.push(item.year);
        }
      });
      this.years1 = years1.join();
    },
    //年费缴费至哪一年
    onSubmit() {
      if (
        this.annual_fee_by_time_num == "" ||
        this.annual_fee_by_time_num == null
      ) {
        this.$message("请选择年费已缴至第几年");
        return;
      }
      if (this.price_not == 2||this.price_not == 3) {
        if (this.percents == "" || this.percents == null) {
          this.$message("请选择费减比例");
          return;
        }
        if(this.percents=='70%'||this.percents=="85%"||this.percents=='70％'||this.percents=="85％"){
           if (this.years == "" || this.years == null) {
          this.$message("请选择费减至哪一年");
          return;
        }
        if (
          this.cost_deduction_time == "" ||
          this.cost_deduction_time == null
        ) {
          this.$message("请选择费减受理日");
          return;
        }
        }
       
      }
      if(!this.check_in_time){
         this.$message("请选择办理登记通知书发文日期");
          return;
      }
      let data = { patent_id: this.pauIds };
      if (this.ann1) {
        data.annual_fee_by_time_num = this.annual_fee_by_time_num;
      }
      if (this.ann2) {
        data.percents = this.percents=='无费减'?'':this.percents;
        data.years = this.years;
        data.cost_deduction_time = this.cost_deduction_time;
      }
      if(this.ann3){
        data.check_in_time=this.check_in_time
      }
      this.$request
        .post("/client/patent/setPerfectInformation", {
          ...data,is_trusteeship:this.is_trusteeship
        })
        .then(res => {
          if (res.data.code == 200) {
            this.dialogVisible2 = false;
            this.getList();
          }
        });
    },
  },
  computed: {
    sum() {
      let snum = this.s_id == 3 ? 20 : 10;
      return snum;
    }
  }
};
</script>

<style scoped>
.is_pay {
  background-color: #eee;
}
.box-card {
  padding-bottom: 48px;
  flex-wrap: wrap;
}
.el-input {
  height: 32px;
  width: 200px;
}
body >>> .el-input__inner {
  height: 32px;
}
.el-select {
  width: 200px;
}

.w-320 {
  width: 320px;
}

.el-tag {
  cursor: pointer;
}
.type-tag {
  display: inline-block;
  background-color: #2962ff;
  height: 20px;
  color: #fff;
  border-radius: 5px;
  padding: 0 6px;
  line-height: 20px;
  width: 60px;
  box-sizing: border-box;
  text-align: center;
}
.pay {
  width: 90px;
  height: 30px;
  background: #2962ff;
  border-radius: 26px;
  margin: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

body >>> .el-switch.is-checked .el-switch__core {
  border-color: #2962ff;
  background-color: #2962ff;
}
.el-tag--plain {
  border-color: #2962ff;
  color: #2962ff;
}
body >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2962ff;
  color: #fff;
}
body >>> .el-button:focus,
.el-button:hover {
  color: #fff;
  border-color: #2962ff;
  background-color: #2962ff;
}
.mont-fee {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.mont-fee-cont {
  width: 936px;
  height: 320px;
  background: #ffffff;
  border-radius: 12px;
  margin: 180px auto;
  border: 1px solid transparent;
  padding: 30px;
  position: relative;
}
body >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #2962ff;
  background: #2962ff;
}
body >>> .el-radio__input.is-checked + .el-radio__label {
  color: #2962ff;
}
.el-radio {
  margin-right: 20px;
}
.mont-fee-btn {
  cursor: pointer;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  width: 96px;
  height: 40px;
  background: #2962ff;
  border-radius: 4px;
}
.cancle {
  position: absolute;
  top: 24px;
  right: 30px;
  font-size: 24px;
  color: #444444;
  cursor: pointer;
}
.top span {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 10px;
}

.el-card >>> .el-table__header-wrapper .el-checkbox {
  display: none;
}
.gd {
  display: flex;
  flex-direction: column;
}
>>>.el-dialog__body{
  padding: 0px 20px 30px;
}
</style>